.post-description {
  display: flex;
  flex-direction: column;
}
.post-title-img {
  margin-bottom: 32px;
  width: 100%;
}
.strong-paragraph {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}
.light-paragraph,
  ol,
  ul {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 24px;
}
.post-caption-img {
  margin: 0 auto 32px auto;
  width: 80%;
}
.caption {
  font-size: 28px;
  font-weight: bold;
  line-height: 42px;
  margin-bottom: 24px;
}
li {
  margin-bottom: 24px;
}
.font {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 24px;
}

@media screen and (min-width: 1024px) {
  .post-title-img {
    margin-bottom: 48px;
  }
  .light-paragraph {
    margin-bottom: 24px;
  }
  .post-caption-img {
    margin: 0 auto 48px auto;
  }
  .caption {
    line-height: 24px;
  }
}
